import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';

export const tableProperties = [
  store.state.selection,
  store.state.index,
  {
    label: '提交日期',
    prop: 'create_time',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    formatter: val => getDateNoTime(val, false)
  },
  { label: '供应商简称', prop: 'supp_abbr', itemType: 'input', input: true, sortable: false, widthAuto: false },
  { label: '销售合同号', prop: 'scon_no', itemType: 'input', input: true, sortable: false, widthAuto: false },
  { label: '采购合同号', prop: 'modr_no', itemType: 'input', input: true, sortable: false, widthAuto: false },
  { label: '客户订单号', prop: 'scon_cust_no', itemType: 'input', input: true, sortable: false, widthAuto: false },
  { label: '客户货号', prop: 'cust_catalog_number', itemType: 'input', input: false, sortable: false, widthAuto: false },
  { label: '中文品名', prop: 'mtrb_name', itemType: 'input', input: false, sortable: false, widthAuto: false },
  { label: '规格', prop: 'mtrb_spec', itemType: 'input', input: false, sortable: false, labelWidth: 120, overflowTooltip: true },
  { label: '成品工厂', prop: 'supp_babbr', itemType: 'input', input: false, sortable: false, labelWidth: 120, overflowTooltip: true },
  {
    label: '产品类型',
    prop: 'prod_ilk',
    itemType: 'select',
    input: true,
    options: [
      { value: 0, label: '新款' },
      { value: 2, label: '翻单' }
    ],
    sortable: false,
    widthAuto: false,
    formatter: val => (val === 0 ? '新款' : val === 2 ? '翻单' : '暂无')
  },
  { label: 'AQL标准', prop: 'aql_standard', itemType: 'input', input: false, sortable: false, widthAuto: false },
  { label: '订单数量', prop: 'mtrb_num', itemType: 'input', input: false, sortable: true, widthAuto: false },
  { label: '已验货数量', prop: 'inspected_num', itemType: 'input', input: false, sortable: false, widthAuto: false },
  { label: '需验货数量', prop: 'goods_inspected', itemType: 'input', input: false, sortable: false, widthAuto: false },
  { label: '数量单位', prop: 'mtrb_unit', itemType: 'input', input: false, sortable: false, widthAuto: false },
  {
    label: '船装运日期',
    prop: 'scon_shdate',
    itemType: 'input',
    input: false,
    sortable: true,
    widthAuto: false,
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: '采购合同交期',
    prop: 'modr_pedate',
    itemType: 'input',
    input: false,
    sortable: true,
    widthAuto: false,
    formatter: val => getDateNoTime(val, false)
  },
  { label: '销售部门', prop: 'cust_dept_name', itemType: 'input', input: true, sortable: false, widthAuto: false },
  { label: '采购经办人', prop: 'modr_stff_name', itemType: 'input', input: true, sortable: false, widthAuto: false }
];
export const dialogTableProperties = [
  { label: '供应商简称', prop: 'supp_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '销售合同号', prop: 'scon_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '采购合同号', prop: 'modr_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户订单号', prop: 'scon_cust_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户货号', prop: 'cust_catalog_number', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '需验货数量', prop: 'goods_inspected', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '采购合同交期',
    prop: 'modr_pedate',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: '船装运日期',
    prop: 'scon_shdate',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    formatter: val => getDateNoTime(val, false)
  }
];
